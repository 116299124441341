import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import DashboardIcon from 'assets/icons/Dashboard';
import OvertimeIcon from 'assets/icons/Overtime';
import SvgPeopleIcon from 'assets/icons/People';
import ReportIcon from 'assets/icons/Report';
import WorkSpaceIcon from 'assets/icons/Workspace';

import { dataSelector } from 'shared/redux/selectors';

import { persona as personaTypes } from 'shared/types';

import { getTranslatedWord } from 'shared/utils/tools';
import { permissionSelector } from 'shared/hocs/withAuth';
import TimeSchedulesIcon from 'assets/icons/TimeSchedules';

const MobileNavBar = () => {
  const location = useLocation();
  const { persona } = useSelector(dataSelector);
  const { IS_SCHEDULE_ACTIVE, CAN_MANAGE_SCHEDULES, CAN_SEE_SCHEDULES } = useSelector(permissionSelector);

  const theme = useTheme();

  const isActive = (tabName: string) => {
    return location.pathname.includes(tabName);
  };

  const numColumns = useMemo(() => {
    let columns = persona === personaTypes[1] ? 5 : 3;
    if (IS_SCHEDULE_ACTIVE) {
      return columns + 1;
    }

    return columns;
  }, [IS_SCHEDULE_ACTIVE, persona]);

  const canSeeSchedules = useMemo(() => {
    if (!IS_SCHEDULE_ACTIVE) {
      return false;
    }

    if (persona === personaTypes[2] && CAN_SEE_SCHEDULES) {
      return true;
    }

    return persona === personaTypes[1] && CAN_MANAGE_SCHEDULES;
  }, [IS_SCHEDULE_ACTIVE, CAN_SEE_SCHEDULES, CAN_MANAGE_SCHEDULES, persona]);

  return (
    <Container id="mobile-header" columns={numColumns}>
      <MenuItem
        style={{
          opacity: isActive('/dashboard') ? 1 : 0.6,
          color: isActive('/dashboard') ? theme.colors.main : theme.colors.gray.dark,
        }}
        to="/dashboard"
      >
        <div>
          <DashboardIcon fill={isActive('/dashboard') ? theme.colors.main : theme.colors.gray.dark} />
        </div>
        {getTranslatedWord('Dashboard', 'dashboard')}
      </MenuItem>
      <MenuItem
        style={{
          opacity: isActive('/timesheet') ? 1 : 0.6,
          color: isActive('/timesheet') ? theme.colors.main : theme.colors.gray.dark,
        }}
        to="/timesheet"
      >
        <div>
          <OvertimeIcon fill={isActive('/timesheet') ? theme.colors.main : theme.colors.gray.dark} />
        </div>
        {getTranslatedWord('Timesheet', 'timesheet')}
      </MenuItem>
      {canSeeSchedules && (
        <MenuItem
          style={{
            opacity: isActive('/schedules') ? 1 : 0.6,
            color: isActive('/schedules') ? theme.colors.main : theme.colors.gray.dark,
          }}
          to="/schedules"
        >
          <div>
            <TimeSchedulesIcon fill={isActive('/schedules') ? theme.colors.main : theme.colors.gray.dark} />
          </div>
          {getTranslatedWord('Schedules')}
        </MenuItem>
      )}
      <MenuItem
        style={{
          opacity: isActive('/raport') ? 1 : 0.6,
          color: isActive('/raport') ? theme.colors.main : theme.colors.gray.dark,
        }}
        to="/raport"
      >
        <div>
          <ReportIcon fill={isActive('/raport') ? theme.colors.main : theme.colors.gray.dark} />
        </div>
        {getTranslatedWord('Reports', 'reports')}
      </MenuItem>
      {persona === personaTypes[1] && (
        <Fragment>
          <MenuItem
            style={{
              opacity: isActive('/people') ? 1 : 0.6,
              color: isActive('/people') ? theme.colors.main : theme.colors.gray.dark,
            }}
            to="/people"
          >
            <div>
              <SvgPeopleIcon fill={isActive('/people') ? theme.colors.main : theme.colors.gray.dark} />
            </div>
            {getTranslatedWord('People', 'people')}
          </MenuItem>
          <MenuItem
            style={{
              opacity: isActive('/workspace') ? 1 : 0.6,
              color: isActive('/workspace') ? theme.colors.main : theme.colors.gray.dark,
            }}
            to="/workspace"
          >
            <div>
              <WorkSpaceIcon fill={isActive('/workspace') ? theme.colors.main : theme.colors.gray.dark} />
            </div>
            {getTranslatedWord('Workspaces', 'workspaces')}
          </MenuItem>
        </Fragment>
      )}
    </Container>
  );
};

const Container = styled.div<{ columns?: any }>`
  // display: flex;

  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  justify-content: flex-start;
  padding: 8px;

  position: fixed;
  bottom: 0;
  min-height: 90px;
  background-color: ${({ theme }) => theme.colors.yellow};
  align-items: center;
  // justify-content: space-around;
  width: 100vw;
  z-index: 1000;

  ${({ theme }) => theme?.mediaQueries?.largeDown} {
    min-height: 56px;
    font-size: 10px;
    box-shadow: 0px 4px 5px 5px rgba(215, 210, 198, 0.8);
  }
`;

const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  // line-height: 30px;
  font-size: 10px;
  text-decoration: none;
  font-weight: 500;
  min-height: 40px;
  // white-space: nowrap;
  height: 100%;
  text-align: center;
  div {
    height: 24px;
    width: 24px;
    max-height: 24px;
    min-height: 24px;
    // margin: auto;
  }
`;

export default MobileNavBar;
