import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { GlobalState } from 'shared/redux/reducer';
import { authRequest, User } from 'shared/redux/auth/reducer';
import { dataSelector } from 'shared/redux/selectors';
import useLocaleChange from 'shared/hooks/useLocaleChange';

import Box from 'shared/components/Box';
import HoursLogoIcon from 'assets/icons/hourslogo.svg';
import styled from 'styled-components';

import {
  CAN_EDIT_PREFERENCES,
  CAN_CONFIRM_WORK_HOURS,
  CAN_GENERATE_WORKER_REPORTS,
  CAN_MANAGE_WORKSPACES,
  CAN_MANAGE_WORKTYPES,
  CAN_MANAGE_SALARY,
  CAN_MANAGE_BOXES,
  CAN_MANAGE_USERS,
  CAN_MANAGE_USER_RIGHTS,
  CAN_MANAGE_USER_WORK_HOURS,
  CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
  CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX,
  CAN_REGISTER_OWN_WORK_FROM_MOBILE,
  CAN_EDIT_OWN_WORK,
  CAN_EDIT_OWN_PROFILE,
  CAN_GENERATE_REPORTS,
  VISIBLE_ON_LOGIN_BOX,
  CAN_USE_TIMERS,
  TRACK_LOCATION,
  CAN_QR_LOGIN,
  CAN_MANAGE_ADMIN_PAGES,
  HAS_MANAGER_RIGHTS,
  CAN_SEE_SCHEDULES,
  CAN_MANAGE_SCHEDULES,
} from 'pages/People/model/user_rights';

export const authSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  state => state.data.persona,
  state => state.auth.sessionLoading,
  state => state.auth.sessionRequested,
  state => state.data.permissions,
  (auth, persona, sessionLoading, sessionRequested, permissions) => {
    const isManager = Boolean(auth?.roles?.find(role => role.name === 'manager'));
    const isAdmin = Boolean(auth?.roles?.find(role => role.name === 'admin'));
    const isMainCompanyUser = auth?.default_company?.main_user_id === auth?.id;
    const isVismaActive = Boolean(auth?.default_company?.visma_active);
    const isMeritPalkActive = Boolean(auth?.default_company?.merit_palk_active);
    const isCustomReportActive = Boolean(auth?.default_company?.custom_report_active);
    const isShiftManager = Boolean(auth?.default_company?.shift_manager_active);
    const isProductionBonus = Boolean(auth?.default_company?.production_bonus_active);

    return {
      auth,
      persona,
      sessionLoading,
      sessionRequested,
      isManager,
      isAdmin,
      isMainCompanyUser,
      isVismaActive,
      isMeritPalkActive,
      isCustomReportActive,
      permissions,
      isShiftManager,
      isProductionBonus,
    };
  },
);

export const permissionSelector = createSelector(
  (state: GlobalState) => state.auth.user,
  user => {
    const permissions = user?.user_rights?.permissions || [];

    return {
      CAN_EDIT_PREFERENCES: permissions.includes(CAN_EDIT_PREFERENCES),
      CAN_CONFIRM_WORK_HOURS: permissions.includes(CAN_CONFIRM_WORK_HOURS),
      CAN_GENERATE_WORKER_REPORTS: permissions.includes(CAN_GENERATE_WORKER_REPORTS),
      CAN_MANAGE_SCHEDULES: permissions.includes(CAN_MANAGE_SCHEDULES),
      CAN_MANAGE_WORKSPACES: permissions.includes(CAN_MANAGE_WORKSPACES),
      CAN_MANAGE_WORKTYPES: permissions.includes(CAN_MANAGE_WORKTYPES),
      CAN_MANAGE_SALARY: permissions.includes(CAN_MANAGE_SALARY),
      CAN_MANAGE_BOXES: permissions.includes(CAN_MANAGE_BOXES),
      CAN_MANAGE_USERS: permissions.includes(CAN_MANAGE_USERS),
      CAN_MANAGE_USER_RIGHTS: permissions.includes(CAN_MANAGE_USER_RIGHTS),
      CAN_MANAGE_USER_WORK_HOURS: permissions.includes(CAN_MANAGE_USER_WORK_HOURS),
      CAN_REGISTER_OWN_WORK_FROM_COMPUTER: permissions.includes(CAN_REGISTER_OWN_WORK_FROM_COMPUTER),
      CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX: permissions.includes(CAN_REGISTER_OWN_WORK_FROM_LOGIN_BOX),
      CAN_REGISTER_OWN_WORK_FROM_MOBILE: permissions.includes(CAN_REGISTER_OWN_WORK_FROM_MOBILE),
      CAN_EDIT_OWN_WORK: permissions.includes(CAN_EDIT_OWN_WORK),
      CAN_EDIT_OWN_PROFILE: permissions.includes(CAN_EDIT_OWN_PROFILE),
      CAN_SEE_SCHEDULES: permissions.includes(CAN_SEE_SCHEDULES),
      CAN_GENERATE_REPORTS: permissions.includes(CAN_GENERATE_REPORTS),
      VISIBLE_ON_LOGIN_BOX: permissions.includes(VISIBLE_ON_LOGIN_BOX),
      CAN_USE_TIMERS: permissions.includes(CAN_USE_TIMERS),
      TRACK_LOCATION: permissions.includes(TRACK_LOCATION),
      CAN_QR_LOGIN: permissions.includes(CAN_QR_LOGIN),
      CAN_MANAGE_ADMIN_PAGES: permissions.includes(CAN_MANAGE_ADMIN_PAGES),
      HAS_MANAGER_RIGHTS: permissions.includes(HAS_MANAGER_RIGHTS),
      IS_VISMA_ACTIVE: user?.default_company?.visma_active || false,
      IS_MERIT_PALK_ACTIVE: user?.default_company?.merit_palk_active || false,
      BOXES_ACTIVE: user?.default_company?.boxes_active || false,
      IS_INNER_RANGE_ACTIVE: user?.default_company?.inner_range_active || false,
      IS_GEOFENCE_ACTIVE: user?.default_company?.geofence_active || false,
      IS_SCHEDULE_ACTIVE: user?.default_company?.schedule_active || false,
      IS_CUSTOM_REPORT_ACTIVE: user?.default_company?.custom_report_active || false,
      IS_MANAGE_SHIFT_ACTIVE: user?.default_company?.shift_manager_active || false,
      IS_PROUDCTION_BONUS_ACTIVE: user?.default_company?.production_bonus_active || false,
    };
  },
);

const withAuth = (WrappedComponent: any) => {
  function Auth(props: any) {
    const { dispatchLocaleChangeFromURL } = useLocaleChange();
    const token = localStorage.getItem('access_token');
    const dispatch = useDispatch();
    const { requireAuth, permissions = [], personas = [] } = props;

    const { auth, sessionLoading, sessionRequested, persona } = useSelector(authSelector);
    const { isSwitching } = useSelector(dataSelector);
    const { CAN_MANAGE_ADMIN_PAGES } = useSelector(permissionSelector);

    useEffect(() => {
      checkSession();
    }, []);

    useEffect(() => {
      dispatchLocaleChangeFromURL(props.location.pathname, props.location.search);
    }, [props.location]);

    if (((token && sessionLoading) || isSwitching) && props?.pageId !== 'redirect') {
      return (
        <Container>
          <Wrapper>
            <img src={HoursLogoIcon} />
            <div className="spinner spinner-settings" />
          </Wrapper>
        </Container>
      );
    }

    if (!auth && sessionRequested && requireAuth === true) {
      return <Redirect to="/login" />;
    }

    if (auth && requireAuth === false) {
      if (props?.pageId == 'verification') {
        return <WrappedComponent {...props} />;
      }

      return <Redirect to="/" />;
    }

    if (props?.pageId === 'dashboard' && persona === 'admin') {
      return <Redirect to="/admin" />;
    }

    if (props?.pageId === 'admin' && persona === 'manager') {
      return <Redirect to="/dashboard" />;
    }

    if (!CAN_MANAGE_ADMIN_PAGES && isForbidden(auth, persona, permissions, personas)) {
      return <Redirect to="/dashboard" />;
    }

    return <WrappedComponent {...props} />;

    function checkSession() {
      if (!sessionRequested) {
        dispatch(authRequest());
      }
    }
  }

  return Auth;
};

export function isForbidden(user: null | User, persona: string, permissions: string[] = [], personas: string[] = []) {
  if (personas?.length && !personas?.includes(persona)) return true;
  if (permissions?.length == 0) return false;
  if (!user?.user_rights?.permissions) return false;

  const PERMITTED = permissions.some((permission: string) => {
    return user?.user_rights?.permissions?.includes(permission);
  });

  return !PERMITTED;
}

const Container = styled(Box)`
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: auto;
`;

const Wrapper = styled(Box)`
  display: block;
  align-items: center;
  padding-top: 24px;
`;

export default withAuth;
